import '../styles/globals.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

function App({ Component, pageProps }) {
    return (
        <>
            <Header/>
            <main style={{ paddingTop: '70px' }}>
                <Component {...pageProps} />
            </main>
            <Footer />
        </>
    )
}

export default App
