import Link from 'next/link';
import { TbBrandFiverr } from 'react-icons/tb';
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai'
import { useEffect, useState } from 'react';

const Footer = () => {
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch('/api/provenexpert');
            if (res.ok) {
                const data = await res.json();
                setApiData(data);
            } else {
                console.error('Fehler beim Abrufen der Daten');
            }
        };

        fetchData();
    }, []);


    return (
        <footer className="bg-white z-50 border-t">
            <div className="container justify-center mx-auto px-8">
                <div className="flex flex-wrap justify-between py-8">
                    <div className="w-full md:w-1/5 text-center md:text-left mb-8 md:mb-0">
                        <h2 className="uppercase mb-6 font-bold dark:text-black">Social-Media</h2>
                        <ul className="flex justify-center md:justify-start mb-4 space-x-4">
                            <li>
                                <Link href="https://de.fiverr.com/s/8lzlBp" aria-label="Fiverr">
                                    <TbBrandFiverr className="h-6 w-6 dark:text-black" />
                                </Link>
                            </li>
                            <li>
                            <Link href="https://www.linkedin.com/company/deinseoexpert/" aria-label="LinkedIn">
                                <AiFillLinkedin className="h-6 w-6 dark:text-black" />
                            </Link>
                            </li>
                            <li>
                                <Link href="https://github.com/Dennisjoch" aria-label="GitHub">
                                    <AiFillGithub className="h-6 w-6 dark:text-black" />
                                </Link>
                            </li>
                        </ul>
                        <div className="flex items-center justify-center md:justify-start mt-4">
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-yellow-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Rating star</title>
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <p className="ml-2 text-sm font-bold text-gray-900 dark:text-black">5</p>
                            <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
                            <a
                                href="https://www.fiverr.com/dennisjoch"
                                className="text-sm font-medium text-gray-900 underline hover:no-underline dark:text-black"
                            >
                                5 reviews
                            </a>
                        </div>
                    </div>

                    <div className="w-full md:w-3/5 text-center md:text-left mb-8 md:mb-0 flex flex-wrap items-start">
                        <div className="w-full md:w-1/3 inline-block">
                            <h2 className="uppercase mb-6 font-bold dark:text-black">Über</h2>
                            <ul className="mb-4">
                                <li className="mt-2">
                                    <Link href="/about" className="hover:underline text-gray-600 hover:text-blue-900">Über uns</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/termin" className="hover:underline text-gray-600 hover:text-blue-900">Termin Buchen</Link>
                                </li>
                                <li className="mt-2">
                                    <a href="/deinseoexpert_service.pdf" target="_blank" className="hover:underline text-gray-600 hover:text-blue-900">Service PDF</a>
                                </li>
                            </ul>
                        </div>

                        <div className="w-full md:w-1/3 inline-block">
                            <h2 className="uppercase mb-6 font-bold dark:text-black">Dienstleistungen</h2>
                            <ul className="mb-4">
                                <li className="mt-2">
                                    <Link href="/services/seo" className="hover:underline text-gray-600 hover:text-blue-900">SEO</Link>
                                </li>
                                {/* Fügen Sie hier weitere Dienstleistungen hinzu */}
                            </ul>
                        </div>

                        <div className="w-full md:w-1/3 inline-block">
                            <h2 className="uppercase mb-6 font-bold dark:text-black">Ressourcen</h2>
                            <ul className="mb-4">
                                <li className="mt-2">
                                    <Link href="/blog" className="hover:underline text-gray-600 hover:text-blue-900">Blog</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/lexikon" className="hover:underline text-gray-600 hover:text-blue-900">Lexikon</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full md:w-1/5 justify-center md:justify-start md:text-left mb-8 md:mb-0 flex flex-wrap items-start">
                        {apiData && (
                            <div dangerouslySetInnerHTML={{ __html: apiData.html }} />
                        )}
                    </div>
                </div>

                <div className="text-center">
                    <p className="text-sm text-gray-600">
                        © 2023 Dennis Joch - deinseoexpert.de | Alle Rechte vorbehalten.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
