import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import Image from "next/image";
import { HiMenu } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

const variants = {
    open: {
        opacity: 1,
        x: 0,
        transition: { duration: 0.1 }
    },
    closed: {
        opacity: 0,
        x: "100%",
        transition: { duration: 0.1 }
    },
};

const Header = () => {
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <header className="flex items-center bg-white px-4 sm:px-24 py-4 fixed top-0 w-full z-50 justify-between shadow-lg">
            <div className="flex items-center">
                <Link href="/">
                    <Image src="/deinSEOExpertdeLogo.webp" alt="Logo" className="h-12 w-auto" width={150} height={150}/>
                </Link>
            </div>
            <div className="lg:flex justify-end items-center hidden">
                <nav className="mr-4">
                    <Link href="/services">
                        <lang className="mx-2 text-gray-800 hover:text-blue-900">Dienstleistungen</lang>
                    </Link>
                    <Link href="/blog">
                        <lang className="mx-2 text-gray-800 hover:text-blue-900">Blog</lang>
                    </Link>
                    <Link href="/about">
                        <lang className="mx-2 text-gray-800 hover:text-blue-900">Über uns</lang>
                    </Link>
                </nav>
                {router.pathname !== '/contact' && (
                    <Link href="/kontakt">
                        <button
                            type="button"
                            className="text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-900 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Kontaktieren Sie mich
                        </button>
                    </Link>
                )}
            </div>
            <div className="lg:hidden">
                <HiMenu onClick={toggleMenu} className="text-2xl text-black cursor-pointer" />
            </div>
            {isOpen && (
                <>
                    <motion.div
                        className="fixed right-0 top-0 h-full w-64 bg-white overflow-auto z-50 p-4"
                        style={{ backdropFilter: "blur(5px)" }}
                        variants={variants}
                        initial="closed"
                        animate={isOpen ? "open" : "closed"}
                    >
                    <AiOutlineClose onClick={toggleMenu} className="text-2xl text-black cursor-pointer" />
                        <nav className="mt-6">
                            <Link href="/services">
                                <lang onClick={toggleMenu} className="block py-1 text-gray-800 hover:text-blue-900">Dienstleistungen</lang>
                            </Link>
                            <Link href="/blog">
                                <lang onClick={toggleMenu} className="block py-1 text-gray-800 hover:text-blue-900">Blog</lang>
                            </Link>
                            <Link href="/about">
                                <lang onClick={toggleMenu} className="block py-1 text-gray-800 hover:text-blue-900">Über uns</lang>
                            </Link>
                        </nav>
                        {router.pathname !== '/contact' && (
                            <Link href="/kontakt">
                                <button
                                    onClick={toggleMenu}
                                    type="button"
                                    className="text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-900 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-6">
                                    Kontaktieren Sie mich
                                </button>
                            </Link>
                        )}
                    </motion.div>
                    <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={toggleMenu}></div>
                </>
            )}
        </header>
    );
}

export default Header;
